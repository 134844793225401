import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import sectionTemplates from './sections';

class Page extends Component {
  renderSection = name => {
    const { data } = this.props;
    const { sections } = data;
    const SectionModule = sectionTemplates[name];
    const section = sections.edges.find(({ node }) => {
      return name === node.frontmatter.name;
    });
    return (
      <SectionModule
        key={section.node.id}
        data={{ ...data, section: section.node }}
      />
    );
  };
  render() {
    const { data } = this.props;
    const { page } = data;
    return (
      <Layout page={page}>
        {page.frontmatter.sections &&
          page.frontmatter.sections.map(({ name }) => {
            return this.renderSection(name);
          })}
      </Layout>
    );
  }
}

export default Page;

export const query = graphql`
  query GetPage($id: String!, $sections: String) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        metaTitle
        metaDescription
        sections {
          name
        }
      }
    }
    sections: allMarkdownRemark(
      filter: { frontmatter: { name: { regex: $sections } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            name
            title
            date
            lead
            images {
              image {
                childImageSharp {
                  fluid(maxWidth: 512, maxHeight: 512) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            callToActionText
            callToActionLink
          }
          html
          htmlAst
        }
      }
    }
  }
`;
